import { Controller } from '@hotwired/stimulus';

export default class extends Controller<HTMLElement> {
  static values = { autohide: Number };

  // These are magically created by Stimulus
  declare autohideValue: number;
  declare readonly hasAutohideValue: boolean;

  declare timeout: ReturnType<typeof setTimeout>;

  connect() {
    if (!this.hasAutohideValue) return;

    this.timeout = setTimeout(() => {
      this.hide();
    }, this.autohideValue);
  }

  removeElement = () => {
    this.element.remove();
  };

  hide() {
    this.element.addEventListener('transitionend', this.removeElement);
    this.element.classList.add('transition-opacity', 'opacity-0', 'duration-300', 'ease-in-out');
  }

  disconnect() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.element.removeEventListener('transitionend', this.removeElement);
  }
}
