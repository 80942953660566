import { StreamActions } from '@hotwired/turbo';

StreamActions.trigger_checkboxes_change = function () {
  // @ts-expect-error Type definition seems to be outdated
  (this.targetElements as HTMLElement[]).forEach((element) => {
    const event = new CustomEvent('betrained:checkboxes-change', {
      bubbles: true,
    });
    element.dispatchEvent(event);
  });
};

StreamActions.set_react_props = function () {
  const props = JSON.parse(this.getAttribute('props') || '{}') as object;

  // @ts-expect-error Type definition seems to be outdated
  (this.targetElements as HTMLElement[]).forEach((element) => {
    const event = new CustomEvent('betrained:set-react-props', {
      detail: { props },
      bubbles: true,
    });
    element.dispatchEvent(event);
  });
};
