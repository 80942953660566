import { Controller } from '@hotwired/stimulus';
import React from 'react';
import ReactDOM from 'react-dom/client';

import * as components from '../components';

interface Components {
  [name: string]: React.ElementType | undefined;
}

export default class extends Controller<HTMLElement> {
  static values = {
    name: String,
    props: Object,
  };

  // These are magically created by Stimulus
  declare nameValue: string;
  declare readonly hasNameValue: boolean;
  declare propsValue: object;
  declare readonly hasPropsValue: boolean;

  declare root: ReactDOM.Root | undefined | null;

  connect() {
    this.root = ReactDOM.createRoot(this.element);

    const Component = (components as Components)[this.nameValue];
    if (!Component) {
      throw new Error(`Unknown component: ${this.nameValue}`);
    }
    const props = this.propsValue || {};

    this.root.render(
      <React.StrictMode>
        <Component {...props} />
      </React.StrictMode>
    );

    this.element.addEventListener('betrained:set-react-props', this.onPropsUpdate);
    this.element.addEventListener('turbo:before-morph-element', this.onBeforeMorph);
  }

  render() {
    const props = this.propsValue || {};
    const Component = (components as Components)[this.nameValue];
    if (!this.root || !Component) return;
    this.root.render(
      <React.StrictMode>
        <Component {...props} />
      </React.StrictMode>
    );
  }

  /**
   * Updates React component's props based on a Turbo Stream server response
   */
  onPropsUpdate = (event: GlobalEventHandlersEventMap['betrained:set-react-props']) => {
    this.propsValue = event.detail.props;
    this.render();
  };

  /**
   * Intercepts Turbo trying to morph this React component
   *
   * React doesn't like having its DOM manipulated from the outside. By handling this
   * event we can handle Turbo-induced props changes in a React-friendly way (without
   * throwing away the component state).
   */
  onBeforeMorph = (event: GlobalEventHandlersEventMap['turbo:before-morph-element']) => {
    const { newElement } = event.detail;
    if (newElement.dataset.reactNameValue === this.element.dataset.reactNameValue) {
      event.preventDefault();
      this.propsValue = JSON.parse(newElement.dataset.reactPropsValue || '{}') as object;
      this.render();
    } else {
      this.disconnect();
      this.element.addEventListener('turbo:morph-element', () => this.connect(), { once: true });
    }
  };

  disconnect() {
    this.element.removeEventListener('turbo:before-morph-element', this.onBeforeMorph);
    this.element.removeEventListener('betrained:set-react-props', this.onPropsUpdate);
    if (!this.root) return;
    this.root.unmount();
    this.root = null;
  }
}
