import React from 'react';

export default function Input({
  className = '',
  children,
  ...props
}: React.ComponentPropsWithoutRef<'label'>) {
  return (
    <label
      className={`${className} block mb-2 text-sm font-medium text-gray-900 dark:text-white`}
      {...props}
    >
      {children}
    </label>
  );
}
