import React, { useState } from 'react';

export default function Example(props: { counter: number }) {
  const [counter, setCounter] = useState<number>(props.counter);

  return (
    <div className="max-w-sm rounded-lg border border-gray-200 bg-white p-6 shadow dark:border-gray-700 dark:bg-gray-800">
      <h2 className="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">
        React counter
      </h2>
      <p className="mb-3 font-normal text-gray-500 dark:text-gray-400">
        Counter: <span>{counter}</span>
      </p>
      <div className="flex justify-end">
        <button className="btn btn-primary" onClick={() => setCounter((c) => c + 1)}>
          Increment
        </button>
      </div>
    </div>
  );
}
