import { Controller } from '@hotwired/stimulus';

export default class extends Controller<HTMLFormElement> {
  static targets = ['disableWhenNothingSelected'];

  // These are magically created by Stimulus
  declare readonly hasDisableWhenNothingSelectedTarget: boolean;
  declare readonly disableWhenNothingSelectedTarget: HTMLElement;
  declare readonly disableWhenNothingSelectedTargets: HTMLElement[];

  onChangeAnywhere = (event: Event) => {
    const target = event.target as HTMLInputElement;
    if (target.form !== this.element) return;

    this.refreshSelectAllState(target.name == 'select_all');
    this.refreshButtonState();
  };

  onDomChanged = () => {
    this.refreshSelectAllState(false);
    this.refreshButtonState();
  };

  onTurboSubmit = (event: GlobalEventHandlersEventMap['turbo:submit-start']) => {
    // @ts-expect-error Type definitions are incomplete
    const submitButton = event.detail.formSubmission.submitter as HTMLButtonElement;
    const dropdownMenu = submitButton.closest('[role=menu]');
    if (!dropdownMenu) return;
    const dropdown = window.FlowbiteInstances.getInstance('Dropdown', dropdownMenu.id);
    if (!dropdown) return;
    dropdown.hide();
  };

  refreshSelectAllState(selectAllToggled: boolean) {
    const selectAll = this.element.elements.namedItem('select_all') as HTMLInputElement;
    const idsList = this.element.elements.namedItem('ids[]') as NodeList | HTMLInputElement;
    let ids: HTMLInputElement[];
    if (idsList == null) {
      ids = [];
    } else if (idsList instanceof HTMLInputElement) {
      ids = [idsList];
    } else {
      ids = Array.from(idsList) as HTMLInputElement[];
    }

    if (selectAllToggled) {
      // Update id checkboxes
      ids.forEach((id) => (id.checked = selectAll.checked));
    } else {
      // Update "select all" checkbox
      if (ids.length > 0 && ids.every((id) => id.checked)) {
        selectAll.checked = true;
      } else {
        selectAll.checked = false;
      }
    }
  }

  refreshButtonState() {
    const formData = new FormData(this.element);
    const enabled = formData.has('ids[]');

    this.disableWhenNothingSelectedTargets.forEach((el) => {
      if (enabled) {
        el.removeAttribute('disabled');
      } else {
        el.setAttribute('disabled', '');
      }
    });

    // Notify any other listeners, so that they can react to items being selected or not
    document.documentElement.dispatchEvent(
      new CustomEvent('betrained:mass-action-state', {
        detail: { form: this.element, anythingSelected: enabled },
      })
    );
  }

  connect() {
    // We listen to all change events on the page because the checkboxes are outside the <form>
    // element - they're connected to it using form="element_id" attribute.
    document.body.addEventListener('change', this.onChangeAnywhere);

    // Turbo form submission (e.g. search box) can clear the checkboxes state
    document.documentElement.addEventListener('turbo:render', this.onDomChanged);

    // Turbo Streams can add/change/remove checkboxes and then trigger a custom event
    this.element.addEventListener('betrained:checkboxes-change', this.onDomChanged);

    this.refreshButtonState();

    // It looks strange when we submit a form from a dropdown and the dropdown stays
    // open, so let's close it in that case.
    this.element.addEventListener('turbo:submit-start', this.onTurboSubmit);
  }

  disconnect() {
    document.body.removeEventListener('change', this.onChangeAnywhere);
    document.documentElement.removeEventListener('turbo:render', this.onDomChanged);
    this.element.removeEventListener('betrained:checkboxes-change', this.onDomChanged);
    this.element.removeEventListener('turbo:submit-start', this.onTurboSubmit);
  }
}
