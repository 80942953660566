import { Controller } from '@hotwired/stimulus';
import type MuxPlayerElement from '@mux/mux-player';

export default class extends Controller<MuxPlayerElement> {
  static values = { audioTrack: String, textTrack: String };

  declare audioTrackValue: string;
  declare textTrackValue: string;

  connect() {
    this.element.addEventListener('loadedmetadata', () => {
      if (
        this.audioTrackValue &&
        this.element.audioTracks &&
        this.element.audioTracks.length >= 1
      ) {
        const audioTracks = Array.from(this.element.audioTracks);
        if (audioTracks.some((track) => track.language === this.audioTrackValue)) {
          audioTracks.forEach((track) => {
            track.kind = track.language === this.audioTrackValue ? 'main' : 'alternative';
            track.enabled = track.language === this.audioTrackValue;
          });
        }
      }
      if (this.textTrackValue && this.element.textTracks && this.element.textTracks.length >= 1) {
        const textTracks = Array.from(this.element.textTracks);
        if (textTracks.some((track) => track.language === this.textTrackValue)) {
          textTracks.forEach((track) => {
            track.mode = track.language === this.textTrackValue ? 'showing' : 'disabled';
          });
        }
      }
    });
  }

  disconnect() {
    if (!this.element.paused) this.element.pause();
    this.element.autoplay = false;
  }
}
