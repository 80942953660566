import { Controller } from '@hotwired/stimulus';

export default class extends Controller<HTMLSelectElement> {
  onChange = () => {
    const targetURL = this.element.options[this.element.selectedIndex].value;
    Turbo.visit(targetURL);
  };

  connect() {
    this.element.addEventListener('change', this.onChange);
  }

  disconnect() {
    this.element.removeEventListener('change', this.onChange);
  }
}
