import { Controller } from '@hotwired/stimulus';
import Sortable from 'sortablejs';
import { put, Options } from '@rails/request.js';
import { withProgressBar } from '../progress_bar';

/**
 * A wrapper over Sortable.js.
 *
 * Based on https://www.stimulus-components.com/docs/stimulus-sortable
 */
export default class extends Controller<HTMLElement> {
  static values = {
    resourceName: String,
    responseKind: {
      type: String,
      default: 'turbo-stream',
    },
    animation: Number,
    handle: String,
    ghostClass: String,
  };

  declare resourceNameValue: string;
  declare responseKindValue: Options['responseKind'];
  declare animationValue: number;
  declare handleValue: string;
  declare ghostClassValue: string;

  declare sortable: Sortable | null;

  connect() {
    this.sortable = new Sortable(this.element, this.options);
  }

  disconnect() {
    this.sortable?.destroy();
    this.sortable = null;
  }

  onStart = () => {
    this.element.classList.add('dragging');
  };

  onEnd = () => {
    this.element.classList.remove('dragging');
  };

  onUpdate = (event: Sortable.SortableEvent) => {
    const { item, newIndex } = event;
    if (!item.dataset.sortableUpdateUrl) return;
    if (newIndex == null) return; // This is to make TS happy

    const param = this.resourceNameValue ? `${this.resourceNameValue}[position]` : 'position';

    const data = new FormData();

    data.append(param, String(newIndex + 1));

    void withProgressBar(
      put(item.dataset.sortableUpdateUrl, {
        body: data,
        responseKind: this.responseKindValue,
      })
    );
  };

  get options(): Sortable.Options {
    return {
      animation: this.animationValue || 150,
      handle: this.handleValue || undefined,
      onUpdate: this.onUpdate,
      onStart: this.onStart,
      onEnd: this.onEnd,
      ghostClass: this.ghostClassValue || undefined,
      // We need to enable the fallback for Cuprite, which doesn't support native HTML5
      // drag & drop. Selenium supports _only_ the native drag & drop though, so we have
      // to make it depend on the browser used.
      forceFallback: navigator.userAgent === 'Cuprite',
    };
  }
}
