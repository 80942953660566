import { Controller } from '@hotwired/stimulus';
import { Modal } from 'flowbite';

export default class extends Controller<HTMLElement> {
  static values = { autoshow: Boolean };

  // These are magically created by Stimulus
  declare autoshowValue: number;
  declare readonly hasAutoshowValue: boolean;

  declare modal: Modal;

  connect() {
    const id = this.element.id || `modal_${Date.now()}`;
    this.modal = new Modal(this.element, { onHide: this.onHide }, { id });
    if (!this.autoshowValue) return;
    this.show();
  }

  show() {
    this.modal.show();
  }

  hide() {
    this.modal.hide();
  }

  onHide = () => {
    // Stop playing any videos when the modal they're in gets closed.
    this.element.querySelectorAll<HTMLMediaElement>('mux-player').forEach((player) => {
      if (!player.paused) {
        player.pause();
      }
    });
  };

  disconnect() {
    this.modal.destroyAndRemoveInstance();
  }
}
