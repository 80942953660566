import { Controller } from '@hotwired/stimulus';

// We have 3 options wrt Stimulus type annotations:
// 1. Manually declare magically created properties (like below)
// 2. Use https://github.com/ajaishankar/stimulus-typescript#usage
// 3. Use JS for Stimulus
export default class extends Controller<HTMLElement> {
  static targets = ['output'];

  // These are magically created by Stimulus
  declare readonly hasOutputTarget: boolean;
  declare readonly outputTarget: HTMLElement;
  declare readonly outputTargets: HTMLElement[];

  static values = { counter: Number };

  // These are magically created by Stimulus
  declare counterValue: number;
  declare readonly hasCounterValue: boolean;

  connect() {
    this.outputTarget.innerText = String(this.counterValue);
  }

  increment() {
    this.outputTarget.innerText = String(++this.counterValue);
  }
}
