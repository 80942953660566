/**
 * We need this file because the progress bar is not displayed when a request is targeting a turbo
 * frame instead of the entire page. We target `modal` turbo frame when rendering remote modals, so
 * by default there's no progress bar when waiting for the modal to show.
 *
 * `dhh` said he'd like to support `data-turbo-progress-bar="true"` to support such use case, but
 * it's not implemented yet. If it gets there, we should be able to remove this file.
 *
 * @see https://github.com/hotwired/turbo/issues/540
 */

interface TurboAdapter {
  formSubmissionStarted: () => void;
  formSubmissionFinished: () => void;
}

// @ts-expect-error the types for @hotwired/turbo are incomplete
const adapter = Turbo.session.adapter as TurboAdapter;

let inProgress = false;
document.addEventListener('turbo:before-fetch-request', (e) => {
  const target = e.target;
  const button = document.activeElement as HTMLElement;
  if (
    !(target instanceof HTMLElement) ||
    (target.dataset.turboProgressBar !== 'true' && button?.dataset?.turboProgressBar !== 'true')
  )
    return;

  adapter.formSubmissionStarted();
  inProgress = true;
});

document.addEventListener('turbo:before-fetch-response', () => {
  if (inProgress) {
    adapter.formSubmissionFinished();
  }
  inProgress = false;
});
