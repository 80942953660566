import { Controller } from '@hotwired/stimulus';
import type MuxPlayerElement from '@mux/mux-player';
import { post, put } from '@rails/request.js';

export default class extends Controller<HTMLElement> {
  static values = {
    position: Number,
    updatePositionPath: String,
    markShownPath: String,
  };
  static targets = ['player', 'completeForm'];

  declare positionValue: number;
  declare updatePositionPathValue: string;
  declare markShownPathValue: string;
  declare readonly playerTarget: MuxPlayerElement;
  declare readonly completeFormTarget: HTMLFormElement;

  declare intervalHandle: ReturnType<typeof setInterval>;
  declare ended: boolean;

  onVideoEnd = () => {
    this.completeFormTarget.requestSubmit();
    this.ended = true;
  };

  everyTwoSeconds = () => {
    if (this.ended) return;

    const currentPosition = Math.floor(this.playerTarget.currentTime);
    if (currentPosition === this.positionValue) return;

    this.positionValue = currentPosition;

    const data = new FormData();
    data.append('position', String(this.positionValue));
    void put(this.updatePositionPathValue, { body: data });
  };

  connect() {
    this.ended = false;
    this.playerTarget.currentTime = this.positionValue || 0;
    this.playerTarget.addEventListener('ended', this.onVideoEnd);
    this.intervalHandle = setInterval(this.everyTwoSeconds, 2000);
    setTimeout(() => {
      void this.playerTarget.play();
    }, 0);
    void post(this.markShownPathValue);
  }

  disconnect() {
    this.playerTarget.removeEventListener('ended', this.onVideoEnd);
    clearInterval(this.intervalHandle);
  }
}
