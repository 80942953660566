import React from 'react';

export default function Input({
  type = 'text',
  className = '',
  ...props
}: React.ComponentPropsWithoutRef<'input'>) {
  return (
    <input
      type={type}
      className={`${className} border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500 bg-gray-50 dark:text-white`}
      {...props}
    />
  );
}
