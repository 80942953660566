interface ProgressBar {
  setValue: (value: number) => void;
  show: () => void;
  hide: () => void;
}

interface TurboAdapter {
  progressBar: ProgressBar;
}

// @ts-expect-error the types for @hotwired/turbo are incomplete
const progressBar = (Turbo.session.adapter as TurboAdapter).progressBar;

export const show = () => {
  progressBar.setValue(0);
  progressBar.show();
};

export const hide = () => {
  progressBar.setValue(1);
  progressBar.hide();
};

export function withProgressBar<T>(promise: Promise<T>): Promise<T> {
  show();
  void promise.finally(() => hide());
  return promise;
}

export default { show, hide, withProgressBar };
