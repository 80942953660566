import React, { useState, useRef, useEffect, KeyboardEvent } from 'react';

import Label from './flowbite/Label';
import Input from './flowbite/Input';
import Select from './flowbite/Select';

type Props = {
  form_action: string;
  categories: { id: string; label: string }[];
  lessons: { id: string; label: string; category_id: string }[];
};

/**
 * Video categorization form
 *
 * It connects to the `mass_action` form to reuse the `ids[]` checkboxes and do
 * all the other form submission stuff (like authenticity token, Turbo handling etc.)
 */
export default function CategorizationForm(props: Props) {
  const { form_action, categories, lessons } = props;
  const [categoryId, setCategoryId] = useState<string>(categories[0]?.id || 'new');
  const [categoryLabel, setCategoryLabel] = useState<string>('');
  const filteredLessons = lessons.filter((l) => l.category_id === categoryId);
  const [lessonId, setLessonId] = useState<string>(filteredLessons[0]?.id || 'new');
  const [lessonLabel, setLessonLabel] = useState<string>('');
  const [focused, setFocused] = useState<boolean>(false);
  const submitRef = useRef<HTMLButtonElement>(null);
  const [visible, setVisible] = useState(false);

  // Switch to newly created categories/lessons once we get them via props update
  // Also change categories/lessons that were removed
  useEffect(() => {
    let newCategoryId = null;

    if (categoryId === 'new') {
      newCategoryId = categories.find((c) => c.label === categoryLabel)?.id || null;
    } else if (!categories.map((c) => c.id).includes(categoryId)) {
      newCategoryId = categories[0]?.id || 'new';
    }

    let newLessonId = null;
    const availableLessons = lessons.filter((l) => l.category_id === (newCategoryId || categoryId));

    if (lessonId === 'new') {
      newLessonId = availableLessons.find((l) => l.label === lessonLabel)?.id || null;
    } else if (!availableLessons.map((l) => l.id).includes(lessonId)) {
      newLessonId = availableLessons[0]?.id || null;
    }

    if (newCategoryId) {
      setCategoryId(newCategoryId);
      setCategoryLabel('');
    }
    if (newLessonId) {
      setLessonId(newLessonId);
      setLessonLabel('');
    }
  }, [categories, lessons]);

  // Show/hide depending on whether something is selected or not
  useEffect(() => {
    const updateVisibility = (
      event: GlobalEventHandlersEventMap['betrained:mass-action-state']
    ) => {
      const { anythingSelected } = event.detail;
      setVisible(anythingSelected);
    };
    document.documentElement.addEventListener('betrained:mass-action-state', updateVisibility);
    return () => {
      document.documentElement.removeEventListener('betrained:mass-action-state', updateVisibility);
    };
  }, []);

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      // Submit using the correct submit button
      event.preventDefault();
      submitRef.current?.click();
    }
  };

  if (!visible) return;

  return (
    <div
      id="categorization_container"
      className="w-full border border-gray-200 bg-white p-6 pt-4 shadow-md sm:rounded-lg dark:border-gray-600 dark:bg-gray-700"
      onFocus={() => {
        // We're tracking focus, so that we only associate the elements with the form
        // while the focus is in. This way they won't be validated/submitted when the
        // user clicks another button related to the mass_action form.
        if (focused) return;
        setFocused(true);
      }}
      onBlur={(event) => {
        const nextFocusedElement = event.relatedTarget || document.activeElement;
        if (nextFocusedElement && nextFocusedElement.closest('#categorization_container')) return;
        setFocused(false);
      }}
    >
      <div className="flex flex-col md:flex-row gap-4">
        <div className="space-y-2">
          <Label htmlFor="categorization_category_id">Category</Label>
          <Select
            id="categorization_category_id"
            name="category[id]"
            form={focused ? 'mass_action' : undefined}
            value={categoryId}
            onChange={(event) => {
              setCategoryId(event.target.value);
              if (lessonId === 'new') return;
              // Update lessonId too
              const newFilteredLessons = lessons.filter(
                (l) => l.category_id === event.target.value
              );
              if (newFilteredLessons.map((l) => l.id).includes(lessonId)) return;
              setLessonId(newFilteredLessons[0]?.id || 'new');
            }}
          >
            {categories.map((category) => (
              <option value={category.id} key={category.id}>
                {category.label}
              </option>
            ))}
            {categories.length > 0 && <option disabled>──────────</option>}
            <option value="new">New...</option>
          </Select>
          {categoryId === 'new' && (
            <label className="block">
              <span className="sr-only">Category Title</span>
              <Input
                name="category[label]"
                form={focused ? 'mass_action' : undefined}
                value={categoryLabel}
                onChange={(event) => setCategoryLabel(event.target.value)}
                required
                onKeyDown={onKeyDown}
                autoFocus
              />
            </label>
          )}
        </div>

        <div className="space-y-2">
          <Label htmlFor="categorization_lesson_id">Lesson</Label>
          <Select
            id="categorization_lesson_id"
            name="lesson[id]"
            form={focused ? 'mass_action' : undefined}
            value={lessonId}
            onChange={(event) => setLessonId(event.target.value)}
          >
            {filteredLessons.map((lesson) => (
              <option value={lesson.id} key={lesson.id}>
                {lesson.label}
              </option>
            ))}
            {filteredLessons.length > 0 && <option disabled>──────────</option>}
            <option value="new">New...</option>
          </Select>
          {lessonId === 'new' && (
            <label className="block">
              <span className="sr-only">Lesson Title</span>
              <Input
                name="lesson[label]"
                form={focused ? 'mass_action' : undefined}
                value={lessonLabel}
                onChange={(event) => setLessonLabel(event.target.value)}
                required
                onKeyDown={onKeyDown}
                autoFocus={categoryId !== 'new'}
                aria-label="Lesson Title"
              />
            </label>
          )}
        </div>

        <div className="md:pt-7">
          <button
            ref={submitRef}
            className="btn btn-primary"
            form="mass_action"
            formAction={form_action}
          >
            Set
          </button>
        </div>
      </div>
    </div>
  );
}
